import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
  selector: 'payment-response',
  templateUrl: './payment-redirect.component.html',
})
export class PaymentRedirectComponent implements OnInit {


  constructor(
  ) { }

  ngOnInit(): void {
      let loc = window.location.href;
      setTimeout(() => {
          window.top.location.href = window['paymentVerifyUrl'] + loc.split('?')[1];
      }, 3000);
  }



}
