import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html'
})
export class TimeoutComponent implements OnInit {

  constructor(
      private authService: AuthService,
      private router: Router
  ) { }

  ngOnInit(): void {
  }

  routeToLogin() {
    this.authService.logout();
    this.router.navigate(['login'], {fragment:"error:Session expired."});
  }

}
