<main class="page-wrapper">
    <div class="page-title-container">
        <div class="page-title">
        </div>
    </div>
    <div class="not-found-page">
        <div class="four-oh-four">404</div>
        <h1>We couldn't find the page you requested.</h1>
        <button class="primary-button basic" (click)="goHome()">Go Home</button>
    </div>
</main>
