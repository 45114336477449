import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
      // This page should never load
  }

}
