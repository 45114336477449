<div class="login-background">
    <div class="login-container">
        <div class="keytrak-logo login-keytrak-logo"></div>
        <ng-container *ngIf="loginState !== 'Validate' && loginState !== 'Error'">
          <div class="login-text">To help keep our client accounts safe, KeyTrak Supplies now requires multifactor authentication (MFA) for all customer accounts.
          Please click below and follow the prompts to set up MFA for your account and log in to dlrSecured:</div>
          <button class="primary-button-standard" (click)="login()">Login</button>
          <div class="login-text">If you are a Canadian based customer looking to order supplies, please use the link below to order online:</div> 
          <a class="sub-text-button" href="https://catalog-canada.keytrak.com">KeyTrak Canada</a>
        </ng-container>
        <ng-container *ngIf="loginState === 'Validate'">
            <div class="login-text"><div class="spinner"></div>Checking credentials...</div>
            <div class="login-subtext">Please wait while you are redirected.</div>
        </ng-container>
        <ng-container *ngIf="loginState === 'Error'">
          <div class="login-text">To help keep our client accounts safe, KeyTrak Supplies now requires multifactor authentication (MFA) for all customer accounts.
          Please click below and follow the prompts to set up MFA for your account and log in to dlrSecured:</div>
          <button class="primary-button-standard" (click)="login()">Login</button>
          <div class="login-text">If you are a Canadian based customer looking to order supplies, please use the link below to order online:</div> 
          <a class="sub-text-button" href="https://catalog-canada.keytrak.com">KeyTrak Canada</a>
          <div class="auth-error">{{error}}</div>
        </ng-container>
    </div>
</div>
