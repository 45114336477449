import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from "./login/login.component";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {LockoutComponent} from "./lockout/lockout.component";
import {TimeoutComponent} from "./timeout/timeout.component";
import {NoCompanyComponent} from './no-company/no-company.component';
import {JwtValidateComponent} from "./jwt-validate/jwt-validate.component";
import { LogoutComponent } from './logout/logout.component';



@NgModule({
  declarations: [
      LoginComponent,
      LockoutComponent,
      TimeoutComponent,
      JwtValidateComponent,
      NoCompanyComponent,
      LogoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ]
})
export class AuthModule { }
