import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'jwt-validate',
  templateUrl: './jwt-validate.component.html'
})
export class JwtValidateComponent implements OnInit {

  constructor(
      private authService: AuthService,
      private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.completeAuthentication().then(response => {
      this.router.navigateByUrl(this.authService.redirectUriAfterLogin);
    }).catch(error => {
      this.router.navigate(['login'], {fragment:"error:"+error});
    })
  }

}
