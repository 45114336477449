import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app/app.module';

if (window['production']) {
  enableProdMode();
  Sentry.init({
    dsn: "https://87366bf9e5224da185c645a5cac17ebd@o76416.ingest.sentry.io/6157289",
    integrations: [
      new BrowserTracing()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
