import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToastService} from "../../service/toast.service";

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {

  public toastString: string;
  public toastVisible: boolean;
  public paused: boolean;

  private toastDataSubscription;
  private toastTimer;
  private toastTimeRemaining = 0;
  private readonly DECREMENT_INTERVAL = 500;

  constructor(
      private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.toastDataSubscription = this.toastService.toastStream.subscribe(next => {
      this.toastString = next.text;
      this.showToast(next.duration);
    });
  }

  ngOnDestroy(): void {
    this.toastDataSubscription.unsubscribe();
  }

  showToast(duration) {
    this.toastVisible = true;
    if (this.toastTimeRemaining === 0) {
      this.toastTimeRemaining += duration;
      setTimeout(() => {
        this.paused = true;
        this.toastTimer = window.setInterval(() => {
          this.toastTimeRemaining -= this.DECREMENT_INTERVAL;
          if (this.toastTimeRemaining < 0) {
            this.toastTimeRemaining = 0;
            this.paused = false;
            setTimeout(() => {
              this.toastVisible = false;
            }, 500);
            window.clearInterval(this.toastTimer);
          }
        }, this.DECREMENT_INTERVAL);
      }, 500);
    } else {
      this.toastTimeRemaining += 1000;
    }
  }

}
