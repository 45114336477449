import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ToastComponent} from "./toast/toast.component";
import {AlertModalComponent} from "./alert-modal/alert-modal.component";
import {ToastService} from "../service/toast.service";



@NgModule({
  declarations: [
      ToastComponent,
      AlertModalComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
      ToastComponent,
      AlertModalComponent
  ]
})
export class AlertModule { }
