import { Component, OnInit } from '@angular/core';
import {LockoutGuardService} from "../../service/guards/lockout-guard.service";
import {Router} from "@angular/router";
import {CompanyGuardService} from '../../service/guards/company-guard.service';
import {AuthService} from '../../service/auth.service';

@Component({
  selector: 'app-noCompany',
  templateUrl: './no-company.component.html'
})
export class NoCompanyComponent implements OnInit {

  constructor(
      private authService: AuthService,
      private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.logout();
    // if (!this.companyGuardService) {
    //   this.router.navigate(['catalog']);
    // }
  }

}
