import { Injectable } from '@angular/core';
import {CartItem} from "../common/object/cart-item";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toastSubject = new Subject<any>();
  public toastStream = this.toastSubject.asObservable();

  constructor() {
  }

  makeToast(data: {
    text: string,
    duration: number
  }) {
    this.toastSubject.next(data);
  }
}
