import { Component, OnInit } from '@angular/core';
import {LockoutGuardService} from "../../service/guards/lockout-guard.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-lockout',
  templateUrl: './lockout.component.html'
})
export class LockoutComponent implements OnInit {

  constructor(
      private lockoutService: LockoutGuardService,
      private router: Router
  ) { }

  ngOnInit(): void {
    this.lockoutService.checkIfAccountHasLock().then(locked => {
      if (!locked) {
        this.router.navigate(['catalog']);
      }
    });
  }

}
