import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "../auth/login/login.component";
import {NotFoundComponent} from "../common/not-found/not-found.component";
import {LockoutComponent} from "../auth/lockout/lockout.component";
import {PaymentRedirectComponent} from "../checkout/payment-redirect/payment-redirect.component";
import {TimeoutComponent} from "../auth/timeout/timeout.component";
import {NoCompanyComponent} from '../auth/no-company/no-company.component';
import {JwtValidateComponent} from "../auth/jwt-validate/jwt-validate.component";
import {LogoutGuardService} from "../service/guards/logout-guard.service";
import {LogoutComponent} from "../auth/logout/logout.component";


const routes: Routes = [
  { path: 'logout', canActivate: [LogoutGuardService], component: LogoutComponent},
  { path: 'login', component: LoginComponent},
  { path: 'payment-redirect/:token', component: PaymentRedirectComponent },
  { path: 'lockout', component: LockoutComponent},
  { path: 'no-company', component: NoCompanyComponent},
  { path: 'timeout', component: TimeoutComponent},
  { path: 'dlr-authenticate', component: JwtValidateComponent},
  { path: '', loadChildren: () => import('../common/keytrak-common.module').then(m => m.KeytrakCommonModule)},
  { path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
