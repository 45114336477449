import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {AuthService} from "../auth.service";

@Injectable({
  providedIn: 'root'
})
export class LockoutGuardService implements CanActivate{

  constructor(
      private router: Router,
      private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkIfAccountHasLock().then(onCreditHold => {
      if (onCreditHold) {
        this.router.navigate(['lockout']);
        return false;
      } else {
        return true;
      }
    });
  }

 public checkIfAccountHasLock(): Promise<boolean> {
    return this.authService.loggedInUser.toPromise().then(user => {
      return user.company && user.company.on_credit_hold
    });
  }
}
