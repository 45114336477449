import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Subject, throwError} from 'rxjs';
import {UserManager} from "oidc-client-ts";
import {Router} from "@angular/router";
import {buffer, filter, map, take} from 'rxjs/operators';
import {User} from "../common/object/user";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userSubject: BehaviorSubject<User> = new BehaviorSubject(null);

  get redirectUriAfterLogin() { return localStorage.getItem('redirectUriAfterLogin') }
  set redirectUriAfterLogin(uri: string) { localStorage.setItem('redirectUriAfterLogin', uri) }
  get cachedBearerToken() { return localStorage.getItem('keytrak-bearer') }
  set cachedBearerToken(token: string) { localStorage.setItem('keytrak-bearer', token) }

  get loggedInUser() { return this.userSubject.pipe(
      filter(user => user !== null),
      take(1)
  )}
  get user() { return this.userInfo }
  set user(u: User) {
    this.userInfo = u;
    this.userSubject.next(this.userInfo);
  }

  private manager: UserManager;
  private userInfo: User;

  constructor(
      private http: HttpClient,
      private router: Router
  ) {
    this.manager = new UserManager(window['OIDC_CONFIG']);
  }

  startAuthentication() {
    return this.manager.signinRedirect();
  }

  isLoggedIn() {
    return this.user != null;
  }

  public getCachedUserInfo() {
    return this.http.get(window['shopApiUrl'] + "me").toPromise().then((response : any) => {
      this.user = response;
      return this.getUserCompanyInfo().then((company : any) => {
        this.user.company = company;
        return this.user;
      });
    });
  }

  trashJwt() {
    localStorage.removeItem('keytrak-bearer');
  }

  private getUserCompanyInfo() {
    return this.http.get(window['reyShopApiUrl'] + "company-information").toPromise()
        .then(response => {
          return response;
        });
  }

  completeAuthentication(): Promise<any> {
    this.manager.clearStaleState();
    return this.manager.signinRedirectCallback().then(data => {
      this.cachedBearerToken = data.access_token;
      return this.getCachedUserInfo();
    }).catch(error => {
      if (typeof error.error === "string") {
        error = error.error;
      } else if (error.error.message) {
        error = error.error.message;
      } else {
        error = "Unexpected error.";
      }
      throw new Error(error);
    });
  }

  logout() {
    this.trashJwt();
    this.user = null;
  }

}
