import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../service/auth.service";


type LoginState = "Validate" | "Login" | "Error";

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  public loginState: LoginState = "Login";
  public error: string = '';

  constructor(
      private authService: AuthService,
      private router: Router,
      private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(f => {
      this.checkLoginState();
    });
  }

  checkLoginState() {
    if (this.route.snapshot.fragment) {
      this.loginState = "Error";
      this.error = this.route.snapshot.fragment.replace('error:', '');
    } else if (this.authService.cachedBearerToken) {
      this.loginState = "Validate";
      this.authService.getCachedUserInfo().then(response => {
        this.router.navigateByUrl(this.authService.redirectUriAfterLogin);
      }).catch(error => {
        console.log(error);
        this.router.navigate(['login'], {fragment:"error:Expired session. Please login again."});
      });
    } else {
      this.loginState = "Login";
    }
  }

  login() {
    this.authService.startAuthentication();
  }

}
